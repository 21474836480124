import classnames from 'classnames';

export interface GlobalHeaderProps {
  action?: JSX.Element;
  avatar?: JSX.Element;
  className?: string;
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  title: string;
}

/**
 * @name GlobalHeader
 * @description Header for all pages in the platform with the ability to include actions and an avatar
 */
export const GlobalHeader = (props: GlobalHeaderProps) => {
  const { className, heading: Heading = 'h2', title, action, avatar } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'mx-6 flex flex-row items-center justify-between border-b border-b-gray-8 py-3',
        className,
      )}
    >
      <div className="flex flex-row items-center">
        {avatar && <span>{avatar}</span>}
        <Heading className="text-2xl font-500">{title}</Heading>
      </div>
      {action && <span>{action}</span>}
    </div>
  );
};
